import { SalesReport } from "./sales-report";

export class BankReport {
    Id: number;
    TransactionDate: string;
    Description: string;
    Withdrawal?: number;
    Deposit?: number;
    Balance: number;
    ReconsilationAmount: number;
}

export class BankReportVM extends BankReport {

    constructor() {
        super();
        this.SalesReports = [];
    }

    SalesReports: SalesReport[];
    Reconciled: boolean;
    RemainingReconAmount: number;
}
