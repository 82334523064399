import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CashDeposit } from 'src/app/models/cash-book/cash-deposit';
import { CashDepositService } from 'src/app/services/cash-book/cash-deposit.service';
import { CashbookService } from 'src/app/services/cash-book/cashbook.service';
import { StaticService } from 'src/app/services/static.service';

@Component({
  selector: 'app-cash-deposit',
  templateUrl: './cash-deposit.component.html',
  styleUrls: ['./cash-deposit.component.css']
})
export class CashDepositComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private spinnerService: Ng4LoadingSpinnerService,
    private cashDepositService: CashDepositService,
    private cashBookService: CashbookService) { }

  addEditFrom: FormGroup;
  errorMsg: string;
  successMsg: string;
  cashDepositList: CashDeposit[] = [];

  ngOnInit() {
    this.createAddForm();
    this.SetCashBalance();
    this.loadGridData();
  }


  save() {

    if (!this.isFormValid()) {
      return;
    }

    let cashDeposit = this.addEditFrom.value;
    cashDeposit.AddedBy = StaticService.getUserNameAtLocStorage();

    this.spinnerService.show();
    this.cashDepositService.save(cashDeposit).subscribe(
      data => {
        this.successMsg = data;
        this.addEditFrom.reset();

        this.addEditFrom.controls.Id.setValue(0);
        this.addEditFrom.controls.DateTime.setValue(this.datePipe.transform(new Date(), 'y-MM-dd'));
        this.loadGridData();
        this.SetCashBalance();
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )

  }

  isFormValid(): Boolean {

    if (this.addEditFrom.controls.DateTime.invalid) {
      this.addEditFrom.controls.DateTime.markAsTouched();
      console.log(this.addEditFrom.errors);
      return false;
    }

    if (this.addEditFrom.controls.Amount.invalid) {
      this.addEditFrom.controls.Amount.markAsTouched();
      console.log(this.addEditFrom.errors);
      return false;
    }

    if (this.addEditFrom.controls.Remark.invalid) {
      this.addEditFrom.controls.Remark.markAsTouched();
      console.log(this.addEditFrom.errors);
      return false;
    }

    return true;
  }

  loadGridData() {
    this.spinnerService.show();
    this.cashDepositService.gets().subscribe(
      data => {
        this.cashDepositList = data;
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  SetCashBalance() {
    this.spinnerService.show();
    this.cashBookService.GetCashBalance().subscribe(
      data => {
        this.addEditFrom.controls.CashBalance.setValue(data);
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  editCashDeposit(cashDeposit: CashDeposit): void {

    // this.addEditFrom.reset();
    this.addEditFrom.controls.Id.setValue(cashDeposit.Id);
    this.addEditFrom.controls.DateTime.setValue(this.datePipe.transform(cashDeposit.DateTime, 'dd-MMM-yyyy hh:mm aa'));
    this.addEditFrom.controls.Amount.setValue(cashDeposit.Amount);
    this.addEditFrom.controls.Remark.setValue(cashDeposit.Remark);
  }

  deleteCashDeposit(cashDeposit: CashDeposit): void {

    if (!confirm("Are you sure to delete data")) {
      return;
    }

    cashDeposit.IsDeleted = true;
    cashDeposit.DeletedBy = StaticService.getUserNameAtLocStorage();
    this.spinnerService.show();
    this.cashDepositService.delete(cashDeposit).subscribe(
      data => {
        this.successMsg = data;
        this.loadGridData();
        this.SetCashBalance();
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      });
  }


  createAddForm(): void {
    this.addEditFrom = this.formBuilder.group({
      Id: [0],
      DateTime: [this.datePipe.transform(new Date(), 'y-MM-dd'), Validators.required],
      CashBalance: [],
      Amount: [, Validators.required],
      Remark: [, Validators.required],
      AddedBy: []
    })
  }

}
