import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CashBook } from 'src/app/models/cash-book/cashbook';
import { DateSearch } from 'src/app/models/SalesBankMapping/MappingPageResponse';
import { StaticService } from '../static.service';

@Injectable({
  providedIn: 'root'
})
export class CashbookService {


  constructor(private httpClient: HttpClient) {
  }

  ServiceUrl: string = StaticService.BaseServiceUrl + "CashBook";


  get(dateSearch: DateSearch): Observable<CashBook[]> {
    let url = this.ServiceUrl + '/Report?' +
      'StartDate=' + dateSearch.StartDate + '&EndDate=' + dateSearch.EndDate;

    return this.httpClient.get<CashBook[]>(url);
  }

  GetCashBalance(): Observable<number> {
    let url = this.ServiceUrl + "/CashBalance";
    return this.httpClient.get<number>(url);
  }

  GetTotalCashBalance(tillDate: string): Observable<number> {
    let url = this.ServiceUrl + "/CashBalance/" + tillDate;
    return this.httpClient.get<number>(url);
  }

}
