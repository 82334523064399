import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BankReportSearch, MappingPageResponse } from 'src/app/models/SalesBankMapping/MappingPageResponse';
import { SalesBankReportMapping } from 'src/app/models/SalesBankMapping/sales-bank-report-mapping';
import { StaticService } from '../static.service';

@Injectable({
  providedIn: 'root'
})
export class SalesBankReportMappingService {

  constructor(private httpClient: HttpClient) {
  }

  ServiceUrl: string = StaticService.BaseServiceUrl + "SalesBankReportMapping";


  gets(): Observable<SalesBankReportMapping[]> {
    return this.httpClient.get<SalesBankReportMapping[]>(this.ServiceUrl);
  }

  GetMappingPageData(bankReportSearch: BankReportSearch): Observable<MappingPageResponse> {

    let queryParams = new HttpParams();
    queryParams.set('StartDate', bankReportSearch.StartDate);
    queryParams.set('EndDate', bankReportSearch.EndDate);
    let url = this.ServiceUrl + '/GetMappingPageData?' +
      'StartDate=' + bankReportSearch.StartDate + '&EndDate=' + bankReportSearch.EndDate;

    return this.httpClient.get<MappingPageResponse>(url);
  }

  save(salesBankMapping: SalesBankReportMapping): Observable<string> {
    return this.httpClient.post<string>(this.ServiceUrl, salesBankMapping);
  }

  

  delete(bankReportId: number): Observable<string> {
    let url = this.ServiceUrl + '/DeleteMapping/' + bankReportId;
    return this.httpClient.get<string>(url);
  }
}
