import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CashExpense } from 'src/app/models/cash-book/cash-expense';
import { ExpenseType } from 'src/app/models/cash-book/expense-type';
import { CashExpenseService } from 'src/app/services/cash-book/cash-expense.service';
import { CashbookService } from 'src/app/services/cash-book/cashbook.service';
import { StaticService } from 'src/app/services/static.service';

@Component({
  selector: 'app-cash-expense',
  templateUrl: './cash-expense.component.html',
  styleUrls: ['./cash-expense.component.css']
})
export class CashExpenseComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private spinnerService: Ng4LoadingSpinnerService,
    private cashExpenseService: CashExpenseService,
    private cashBookService: CashbookService) { }

  addEditFrom: FormGroup;
  searchForm: FormGroup;
  errorMsg: string;
  successMsg: string;
  cashExpenseList: CashExpense[] = [];
  expenseTypes: ExpenseType[] = [];
  totalCashExpense: number = 0;

  ngOnInit() {
    this.createAddForm();
    this.createSearchForm();
    this.SetCashBalance();
    this.SetExpenseType();
    this.loadGridData(this.searchForm);
  }


  save() {

    if (!this.isFormValid()) {
      return;
    }

    let cashExpense = this.addEditFrom.value;
    cashExpense.AddedBy = StaticService.getUserNameAtLocStorage();


    this.spinnerService.show();
    this.cashExpenseService.save(cashExpense).subscribe(
      data => {
        this.successMsg = data;
        this.addEditFrom.reset();

        this.addEditFrom.controls.Id.setValue(0);
        this.addEditFrom.controls.DateTime.setValue(this.datePipe.transform(new Date(), 'y-MM-dd'));
        this.addEditFrom.controls.ExpenseType.setValue("");
        this.loadGridData();
        this.SetCashBalance();
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )

  }

  isFormValid(): Boolean {

    if (this.addEditFrom.controls.DateTime.invalid) {
      this.addEditFrom.controls.DateTime.markAsTouched();
      console.log(this.addEditFrom.errors);
      return false;
    }

    if (this.addEditFrom.controls.Amount.invalid) {
      this.addEditFrom.controls.Amount.markAsTouched();
      console.log(this.addEditFrom.errors);
      return false;
    }

    if (this.addEditFrom.controls.ExpenseType.invalid) {
      this.addEditFrom.controls.ExpenseType.markAsTouched();
      console.log(this.addEditFrom.errors);
      return false;
    }

    if (this.addEditFrom.controls.Description.invalid) {
      this.addEditFrom.controls.Description.markAsTouched();
      console.log(this.addEditFrom.errors);
      return false;
    }

    return true;
  }

  loadGridData(searchForm?: FormGroup) {
    let cashExpenseSearch = searchForm ? searchForm.value : null;
    this.spinnerService.show();
    this.cashExpenseService.gets(cashExpenseSearch).subscribe(
      data => {
        this.cashExpenseList = data;
        this.totalCashExpense = this.cashExpenseList.reduce(
          (sum, current) => sum + (current.Amount), 0);
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  getExpenseType(id: number) {
    return this.expenseTypes.find(x => x.Id === id).Type;
  }

  SetCashBalance() {
    this.spinnerService.show();
    this.cashBookService.GetCashBalance().subscribe(
      data => {
        this.addEditFrom.controls.CashBalance.setValue(data);
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  SetExpenseType() {
    this.spinnerService.show();
    this.cashExpenseService.GetExpenseTypes().subscribe(
      data => {
        this.expenseTypes = data;
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  editCashExpense(cashExpense: CashExpense): void {

    // this.addEditFrom.reset();
    this.addEditFrom.controls.Id.setValue(cashExpense.Id);
    this.addEditFrom.controls.DateTime.setValue(this.datePipe.transform(cashExpense.DateTime, 'y-MM-dd'));
    this.addEditFrom.controls.Amount.setValue(cashExpense.Amount);
    this.addEditFrom.controls.ExpenseType.setValue(cashExpense.ExpenseType);
    this.addEditFrom.controls.Description.setValue(cashExpense.Description);
  }

  deleteCashExpense(cashExpense: CashExpense): void {

    if (!confirm("Are you sure to delete data")) {
      return;
    }

    cashExpense.IsDeleted = true;
    cashExpense.DeletedBy = StaticService.getUserNameAtLocStorage();
    this.spinnerService.show();
    this.cashExpenseService.delete(cashExpense).subscribe(
      data => {
        this.successMsg = data;
        this.loadGridData();
        this.SetCashBalance();
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      });
  }

  createAddForm(): void {
    this.addEditFrom = this.formBuilder.group({
      Id: [0],
      DateTime: [this.datePipe.transform(new Date(), 'y-MM-dd'), Validators.required],
      CashBalance: [],
      Amount: [, Validators.required],
      ExpenseType: ["", Validators.required],
      Description: [, Validators.required],
      AddedBy: [],
      SearchDateTime: [this.datePipe.transform(new Date(), 'y-MM-dd')]
    })
  }

  createSearchForm(): void {
    let monthStart = new Date().setDate(1);
    this.searchForm = this.formBuilder.group({
      StartDate: [this.datePipe.transform(monthStart, 'y-MM-dd')],
      EndDate: [this.datePipe.transform(new Date(), 'y-MM-dd')],
      ExpenseType: [0]
    })
  }

}
