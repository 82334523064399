import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BankWithdrawal } from 'src/app/models/cash-book/bank-withdrawal';
import { expenseSearch } from 'src/app/models/cash-book/cash-expense';
import { DateSearch } from 'src/app/models/SalesBankMapping/MappingPageResponse';
import { StaticService } from '../static.service';

@Injectable({
  providedIn: 'root'
})
export class BankWithdrawalService {


  constructor(private httpClient: HttpClient) {
  }

  ServiceUrl: string = StaticService.BaseServiceUrl + "BankWithdrawal";


  gets(dateSearch: DateSearch): Observable<BankWithdrawal[]> {
    let url = this.ServiceUrl + '/DateSearch?' +
      'StartDate=' + dateSearch.StartDate + '&EndDate=' + dateSearch.EndDate;
    return this.httpClient.get<BankWithdrawal[]>(url);
  }

  getExpenseReports(expenseSearch: expenseSearch): Observable<BankWithdrawal[]> {
    let url = this.ServiceUrl + '/ExpenseSearch?' +
      'StartDate=' + expenseSearch.StartDate + '&EndDate=' + expenseSearch.EndDate +
      '&ExpenseType=' + expenseSearch.ExpenseType;
    return this.httpClient.get<BankWithdrawal[]>(url);
  }




  save(bankWithdrawal: BankWithdrawal): Observable<string> {
    let url = this.ServiceUrl;
    return this.httpClient.post<string>(url, bankWithdrawal);
  }

  delete(bankWithdrawal: BankWithdrawal): Observable<string> {
    let url = this.ServiceUrl + "/Delete";
    return this.httpClient.post<string>(url, bankWithdrawal);
  }
}
