import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BankReport } from 'src/app/models/SalesBankMapping/bank-report';
import { DateSearch } from 'src/app/models/SalesBankMapping/MappingPageResponse';
import { SalesReport } from 'src/app/models/SalesBankMapping/sales-report';
import { StaticService } from '../static.service';

@Injectable({
  providedIn: 'root'
})
export class SalesReportService {

  constructor(private httpClient: HttpClient) {
  }

  ServiceUrl: string = StaticService.BaseServiceUrl + "SalesReport";


  gets(): Observable<SalesReport[]> {
    return this.httpClient.get<SalesReport[]>(this.ServiceUrl);
  }


  getSalesReport(dateSearch: DateSearch): Observable<SalesReport[]> {
    let url = this.ServiceUrl + "/GetSalesReport" +
      '?StartDate=' + dateSearch.StartDate + '&EndDate=' + dateSearch.EndDate;
    ;
    return this.httpClient.get<SalesReport[]>(url);
  }

  getBankReportBySalesId(salesReportId: number): Observable<BankReport[]> {
    let url = this.ServiceUrl + '/' + salesReportId +'/BankReports';
    return this.httpClient.get<BankReport[]>(url);
  }


  saveUploadReport(salesReports: SalesReport[]): Observable<string> {
    let url = this.ServiceUrl + '/SaveUploadReport';
    let request = JSON.stringify(salesReports);
    return this.httpClient.post<string>(url, salesReports);
  }

}
