import { Component, OnInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HomePageData } from 'src/app/models/cash-book/home-page-data';
import { HomePageDataService } from 'src/app/services/cash-book/home-page-data.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private homePageService: HomePageDataService) { }

  homePageData: HomePageData;
  errorMsg: string = "";



  ngOnInit() {
    this.loadPageData();
  }


  loadPageData() {
    this.spinnerService.show();
    this.homePageService.get().subscribe(
      data => {
        this.homePageData = data;
        this.spinnerService.hide()
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    );
  }

  getTotal(columnName: string) {
    return this.homePageData.MonthlyReports.reduce((sum, current) => sum + current[columnName], 0)
  }
}
