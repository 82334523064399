import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { forkJoin } from 'rxjs';
import { CashBook } from 'src/app/models/cash-book/cashbook';
import { CashbookService } from 'src/app/services/cash-book/cashbook.service';
import { StaticService } from 'src/app/services/static.service';

@Component({
  selector: 'app-cash-book',
  templateUrl: './cash-book.component.html',
  styleUrls: ['./cash-book.component.css']
})
export class CashBookComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private spinnerService: Ng4LoadingSpinnerService,
    private cashBookService: CashbookService) { }

  cashBookList: CashBook[] = [];
  totalCashBalance: any = '';
  tillDate: string = '';
  searchForm: FormGroup;
  successMsg: string = "";
  errorMsg: string = "";

  ngOnInit() {
    this.createSearchForm();
    this.loadCashBook();
  }

  loadCashBook() {
    let searchValue = this.searchForm.value;
    this.spinnerService.show();
    let cashBookRequest = this.cashBookService.get(searchValue)
    let cashBalanceRequest = this.cashBookService.GetTotalCashBalance(searchValue.EndDate);


    this.spinnerService.show();
    forkJoin([cashBookRequest, cashBalanceRequest]).subscribe(
      data => {
        this.cashBookList = data[0];
        this.totalCashBalance = data[1];

        let stringEndDate = this.cashBookList.map(x => x.Date).sort().reverse()[0];
        this.tillDate = this.datePipe.transform(stringEndDate, 'dd-MMM-yyyy');

        this.spinnerService.hide()
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    );
  }

  getUPIColumnValue(cashBook: CashBook) {
    let result: string = cashBook.UPI.toFixed(0).toString();
    if (cashBook.PendingReconsilation > StaticService.ReconsilationZeroLimit) {
      result = result + ' (' + cashBook.PendingReconsilation.toFixed(0) + ')'
    }
    return result;
  }

  getTableRowClass(cashBook: CashBook) {
    if (cashBook.PendingReconsilation > StaticService.ReconsilationZeroLimit) {
      return 'table-warning';
    }
    return 'table-default';
  }

  getTotal(columnName: string) {
    return this.cashBookList.reduce((sum, current) => sum + current[columnName], 0)
  }

  createSearchForm() {
    let fromDate = new Date();
    fromDate.setDate(1);
    let toDate = new Date();

    this.searchForm = this.formBuilder.group({
      StartDate: [this.datePipe.transform(fromDate, 'y-MM-dd'), Validators.required],
      EndDate: [this.datePipe.transform(toDate, 'y-MM-dd'), Validators.required]
    })
  }

}
