import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InwardOutwardComponent } from './components/inward-outward/inward-outward.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { InwardOutwardListComponent } from './components/inward-outward-list/inward-outward-list.component';
import { StockReportComponent } from './components/stock-report/stock-report.component';
import { BillListComponent } from './components/bill-list/bill-list.component';
import { LoginComponent } from './components/login/login.component';
import { ReadingComponent } from './components/reading/reading.component';
import { ReadingReportComponent } from './components/reading-report/reading-report.component';
import { SalesBankReportMapComponent } from './components/SalesBankMapping/sales-bank-report-map/sales-bank-report-map.component';
import { SalesReportComponent } from './components/SalesBankMapping/sales-report/sales-report.component';
import { BankReportComponent } from './components/SalesBankMapping/bank-report/bank-report.component';
import { CashDepositComponent } from './components/cashBook/cash-deposit/cash-deposit.component';
import { CashExpenseComponent } from './components/cashBook/cash-expense/cash-expense.component';
import { BankWithdrawalComponent } from './components/cashBook/bank-withdrawal/bank-withdrawal.component';
import { CashBookComponent } from './components/cashBook/cash-book/cash-book.component';
import { HomePageComponent } from './components/cashBook/home-page/home-page.component';
import { ExpenseSummaryComponent } from './components/cashBook/expense-summary/expense-summary.component';

@NgModule({
  declarations: [
    AppComponent,
    InwardOutwardComponent,
    InwardOutwardListComponent,
    StockReportComponent,
    BillListComponent,
    LoginComponent,
    ReadingComponent,
    ReadingReportComponent,
    SalesBankReportMapComponent,
    SalesReportComponent,
    BankReportComponent,
    CashDepositComponent,
    CashExpenseComponent,
    BankWithdrawalComponent,
    CashBookComponent,
    HomePageComponent,
    ExpenseSummaryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng4LoadingSpinnerModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
