import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { forkJoin } from 'rxjs';
import { ExpenseSummary } from 'src/app/models/cash-book/cash-expense';
import { ExpenseType } from 'src/app/models/cash-book/expense-type';
import { BankWithdrawalService } from 'src/app/services/cash-book/bank-withdrawal.service';
import { CashExpenseService } from 'src/app/services/cash-book/cash-expense.service';

@Component({
  selector: 'app-expense-summary',
  templateUrl: './expense-summary.component.html',
  styleUrls: ['./expense-summary.component.css']
})
export class ExpenseSummaryComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private spinnerService: Ng4LoadingSpinnerService,
    private cashExpenseService: CashExpenseService,
    private bankWithdrawalService: BankWithdrawalService) { }

  searchForm: FormGroup;
  errorMsg: string;
  successMsg: string;
  expenseSummaryList: ExpenseSummary[] = [];
  expenseTypes: ExpenseType[] = [];
  expenseSubGroup: string[] = [];
  totalExpense: number = 0;
  expenseSummaryData: any = {};
  pageDataList: any[] = [];


  ngOnInit() {
    this.createSearchForm();
    this.SetExpenseType();
  }

  getExpenseType(id: number) {
    return this.expenseTypes.find(x => x.Id === id).Type;
  }

  getExpenseGroup(id: number) {
    return this.expenseTypes.find(x => x.Id === id).SubGroup;
  }

  getExpenseSubGroup() {
    return this.expenseTypes.map(x => x.SubGroup);
  }

  expenseSummaryListBySubGroup(subGroup: string) {
    let expenseTypes = this.expenseTypes.filter(x => x.SubGroup === subGroup).map(x => x.Id);
    this.expenseSummaryList.filter(x => expenseTypes.includes(x.ExpenseType))
  }

  SetExpenseType() {
    this.spinnerService.show();
    this.cashExpenseService.GetExpenseTypes().subscribe(
      data => {
        this.expenseTypes = data;
        this.loadGridData(this.searchForm);
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  loadGridData(searchForm?: FormGroup) {
    let cashExpenseSearch = searchForm ? searchForm.value : null;
    this.spinnerService.show();
    this.cashExpenseService.getExpenseSummary(cashExpenseSearch).subscribe(
      data => {
        this.expenseSummaryList = data;
        this.totalExpense = this.expenseSummaryList.reduce(
          (sum, current) => sum + (current.Amount), 0);
        this.createPageData();
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }


  loadExpenseReports(expenseTypeId: number) {

    if (this.expenseSummaryData[expenseTypeId]) {
      this.expenseSummaryData[expenseTypeId].showExpenseReport = !this.expenseSummaryData[expenseTypeId].showExpenseReport;
      return;
    }

    let expenseSearch = this.searchForm.value;
    expenseSearch.ExpenseType = expenseTypeId;

    let bankWithdrawalRequest = this.bankWithdrawalService.getExpenseReports(expenseSearch);
    let cashExpenseRequest = this.cashExpenseService.gets(expenseSearch);
    this.spinnerService.show();

    forkJoin([bankWithdrawalRequest, cashExpenseRequest])
      .subscribe(
        data => {
          data[0].forEach((x: any) => x.Source = 'Bank');
          data[1].forEach((x: any) => x.Source = 'Cash');
          var expenseData: any = data[0];
          expenseData.push(...data[1]);
          this.expenseSummaryData[expenseTypeId] = {
            expenseReports: expenseData,
            showExpenseReport: true
          }
        },
        err => {
          this.errorMsg = err;
          this.spinnerService.hide();
        },
        () => this.spinnerService.hide()
      )
  }

  createPageData() {
    let pageData: any = {};
    let list: any[] = [];
    this.expenseTypes.forEach(expenseType => {
      let expenseSummary = this.expenseSummaryList.find(x => x.ExpenseType === expenseType.Id);
      if (expenseSummary) {
        pageData.ExpenseTypeId = expenseSummary.ExpenseType;
        pageData.SubGroup = expenseType.SubGroup;
        pageData.ExpenseType = expenseType.Type;
        pageData.Amount = expenseSummary.Amount;
        list.push({ ...pageData });
      }
    });
    this.pageDataList = list.sort(this.sortBySubGroup);
  }

  sortBySubGroup(a, b) {
    if (a.SubGroup < b.SubGroup)
      return 1;

    if (a.SubGroup > b.SubGroup)
      return -1;

    return 0

  }

  createSearchForm(): void {
    let monthStart = new Date().setDate(1);
    this.searchForm = this.formBuilder.group({
      StartDate: [this.datePipe.transform(monthStart, 'y-MM-dd')],
      EndDate: [this.datePipe.transform(new Date(), 'y-MM-dd')],
      ExpenseType: [0]
    })
  }



}
