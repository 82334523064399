import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx';
import { BankReport, BankReportVM } from 'src/app/models/SalesBankMapping/bank-report';
import { BankReportSearch } from 'src/app/models/SalesBankMapping/MappingPageResponse';
import { SalesBankReportMapping } from 'src/app/models/SalesBankMapping/sales-bank-report-mapping';
import { SalesReport } from 'src/app/models/SalesBankMapping/sales-report';
import { BankReportService } from 'src/app/services/SalesBankMapping/bank-report.service';
import { SalesBankReportMappingService } from 'src/app/services/SalesBankMapping/sales-bank-report-mapping.service';
import { SalesReportService } from 'src/app/services/SalesBankMapping/sales-report.service';
import { StaticService } from 'src/app/services/static.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-sales-bank-report-map',
  templateUrl: './sales-bank-report-map.component.html',
  styleUrls: ['./sales-bank-report-map.component.css']
})
export class SalesBankReportMapComponent implements OnInit {

  constructor(private spinnerService: Ng4LoadingSpinnerService,
    private salesRepSer: SalesReportService,
    private bankRepSer: BankReportService,
    private salesBankRepMapSer: SalesBankReportMappingService,
    private loginService: LoginService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder) { }

  salesReports: SalesReport[] = [];
  bankReports: BankReportVM[] = [];
  salesBankMaps: SalesBankReportMapping[] = [];
  totalDepositAmount: number;
  successMsg: string = "";
  errorMsg: string = "";
  salesReportId: any = {};
  OtherTexts: any = {};
  searchForm: FormGroup;
  bankReportJsonArray: any[] = [];
  filteredBankReports: BankReportVM[] = [];
  totalPendingRecoAmount: number = 0;
  totalOTAPayment: number = 0;
  totalAdvancePayment: number = 0;
  totalOthersPayment: number = 0;
  totalSales: number = 0;


  ngOnInit() {
    this.createSearchForm();
    this.loadSalesBankReport();
  }

  sortBankDiscription(longDescription: string) {
    return longDescription.replace("BY TRANSFER-UPI/CR/", "UPI-")
  }

  SalesDescription(salesReport: SalesReport) {
    let checkOutDate = this.datePipe.transform(salesReport.CheckOutDate, 'dd-MMM');

    return checkOutDate + "  " + salesReport.GuestName + "  " + salesReport.UPI
      + "  (" + (salesReport.UPI - salesReport.ReconsilationAmount) + ")" + "  " + salesReport.TransDateDescription;
  }

  SalesBadgeDescription(salesReport: SalesReport, bankReportId: number) {

    let checkOutDate = salesReport.CheckOutDate ? this.datePipe.transform(salesReport.CheckOutDate, 'dd-MMM') : '';
    return checkOutDate + " - " + salesReport.GuestName + " - " + this.salesBillReconsilationAmount(salesReport.Id, bankReportId);
  }

  salesBillReconsilationAmount(salesReportId: number, bankReportId: number) {
    var result = this.salesBankMaps.find(x => x.BankReportId === bankReportId && x.SalesReportId === salesReportId)
    return result.ReconsilationAmount;
  }

  saveSalesBankMapping(bankReport: BankReportVM, salesReportId: number, otherText: string) {

    let salesReport: any = this.salesReports.find(x => x.Id == salesReportId);
    if (salesReport && bankReport.RemainingReconAmount >= (salesReport.UPI - salesReport.ReconsilationAmount)) {
      var usedAmount = (salesReport.UPI - salesReport.ReconsilationAmount);
    } else {
      usedAmount = bankReport.RemainingReconAmount;
    }


    let saleBankMap: SalesBankReportMapping = {
      Id: 0,
      SalesReportId: Number(salesReportId),
      BankReportId: Number(bankReport.Id),
      ReconsilationAmount: usedAmount,
      OtherText: otherText,
      MappingDate: new Date(),
      MappedBy: StaticService.getUserNameAtLocStorage()
    };

    this.spinnerService.show();
    this.salesBankRepMapSer.save(saleBankMap).subscribe(
      data => {
        this.salesBankMaps.push(saleBankMap);
        if (saleBankMap.SalesReportId <= 0) {
          salesReport = { Id: saleBankMap.SalesReportId, GuestName: saleBankMap.OtherText };
        }
        this.setSalesBankReportMap(bankReport, salesReport, saleBankMap.ReconsilationAmount);
        this.spinnerService.hide();
      },
      err => {
        alert(err.error.Message);
        console.log(err);
        this.spinnerService.hide();
      })


  }

  deleteSalesBankMapping(bankReport: BankReportVM) {
    this.spinnerService.show();
    this.salesBankRepMapSer.delete(bankReport.Id).subscribe(
      data => {
        bankReport.SalesReports.forEach(salesReport => {
          let usedAmount = this.salesBillReconsilationAmount(salesReport.Id, bankReport.Id);
          bankReport.RemainingReconAmount += usedAmount;
          salesReport.ReconsilationAmount -= usedAmount;
          bankReport.Reconciled = (bankReport.RemainingReconAmount === 0);
          let test = this.salesReports.some(x => x.Id === salesReport.Id);
          if (!this.salesReports.some(x => x.Id === salesReport.Id)) {
            this.salesReports.push(salesReport);
          }
        });
        bankReport.SalesReports = [];

        this.spinnerService.hide();
      },
      err => {
        this.spinnerService.hide();
      }
    );
  }

  Search() {

  }


  loadSalesBankReport() {

    let searchValue = this.searchForm.value;

    this.spinnerService.show();
    this.salesBankRepMapSer.GetMappingPageData(searchValue).subscribe(
      data => {
        this.salesReports = data.SalesReports;
        this.bankReports = data.BankReports.map(x => <BankReportVM>x);
        this.bankReports.forEach(x => {
          x.SalesReports = [];
          x.Reconciled = false;
          x.RemainingReconAmount = x.Deposit;
        });

        // this.salesReports.forEach(x => x.ReconsilationAmount = 0);

        this.salesBankMaps = data.SalesReportMappings;
        this.totalDepositAmount = this.bankReports.reduce((sum, current) => sum + current.Deposit, 0);

        this.LoadSalesBankReportMap();
        this.filteredBankReports = this.bankReports;
        this.SearchBankReportByType();
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }





  LoadSalesBankReportMap() {
    this.salesBankMaps.forEach(salesBankMap => {
      let bankReport = this.bankReports.find(x => x.Id === salesBankMap.BankReportId);

      let salesReport: any;
      if (salesBankMap.SalesReportId <= 0) {
        salesReport = {
          Id: salesBankMap.SalesReportId,
          GuestName: salesBankMap.OtherText,
          ReconsilationAmount: 0
        };
      } else {
        salesReport = this.salesReports.find(x => x.Id === salesBankMap.SalesReportId);
      }

      this.setSalesBankReportMap(bankReport, salesReport, salesBankMap.ReconsilationAmount);
    });

    this.salesReports = this.salesReports.filter(x => x.UPI - x.ReconsilationAmount >= StaticService.ReconsilationZeroLimit);
  }

  setSalesBankReportMap(bankReport: BankReportVM, salesReport: SalesReport, reconsilationAmount: number) {
    bankReport.SalesReports.push(salesReport);
    bankReport.RemainingReconAmount -= reconsilationAmount;
    salesReport.ReconsilationAmount += reconsilationAmount;
    bankReport.Reconciled = (bankReport.RemainingReconAmount <= StaticService.ReconsilationZeroLimit);
  }

  SearchBankReportByType() {

    if (this.searchForm.value.SearchType !== '') {
      this.bankReports = this.SearchByType(this.searchForm.value.SearchType);

    } else {
      this.bankReports = this.filteredBankReports;
    }

    this.totalPendingRecoAmount = this.filteredBankReports.reduce(
      (sum, current) => sum + (current.RemainingReconAmount), 0);

    this.totalOTAPayment = this.filteredBankReports.filter(x => x.SalesReports.length > 0 && x.SalesReports[0].Id == -2).reduce(
      (sum, current) => sum + (current.Deposit), 0);

    this.totalOthersPayment = this.filteredBankReports.filter(x => x.SalesReports.length > 0
      && x.SalesReports[0].Id == 0).reduce(
        (sum, current) => sum + (current.Deposit), 0);

    this.totalSales = this.filteredBankReports.filter(x => x.SalesReports.length > 0
      && x.SalesReports[0].Id > 0
      && x.SalesReports.filter(y => y.CheckOutDate
        && this.datePipe.transform(y.CheckOutDate, 'yyyy-MM-dd') <= this.searchForm.value.EndDate).length > 0).reduce(
          (sum, current) => sum + (current.Deposit - current.RemainingReconAmount), 0);


    var advanceDepositBeforeEndDate = this.filteredBankReports.filter(x => x.SalesReports.length > 0
      && x.SalesReports.filter(y => y.CheckOutDate
        && this.datePipe.transform(y.CheckOutDate, 'yyyy-MM-dd') > this.searchForm.value.EndDate).length > 0);

    // advanceDepositBeforeEndDate.reduce(
    //   (sum, current) => sum + (current.SalesReports), 0);


    this.totalAdvancePayment = this.filteredBankReports.filter(x => x.SalesReports.length > 0 && x.SalesReports[0].Id == -1)
      .concat(advanceDepositBeforeEndDate).reduce(
        (sum, current) => sum + (current.Deposit), 0);
  }


  SearchByType(type: string): BankReportVM[] {

    if (type === 'Pending') {
      return this.filteredBankReports.filter(x => !x.Reconciled);
    }

    if (type == 'Others') {
      return this.filteredBankReports.filter(x => x.SalesReports.length > 0
        && x.SalesReports[0].Id == 0);
    }

    if (type === 'OTA') {
      return this.filteredBankReports.filter(x => x.SalesReports.length > 0 && x.SalesReports[0].Id == -2);
    }

    if (type === 'Advance') {

      var advanceBeforeEndDate = this.filteredBankReports.filter(x => x.SalesReports.length > 0
        && x.SalesReports.filter(y => y.CheckOutDate
          && this.datePipe.transform(y.CheckOutDate, 'yyyy-MM-dd') > this.searchForm.value.EndDate).length > 0);

      return this.filteredBankReports.filter(x => x.SalesReports.length > 0 && x.SalesReports[0].Id == -1)
        .concat(advanceBeforeEndDate);
    }
  }

  // advanceDepositSalesBeforeEndDate(bankReport: BankReportVM){

  //   bankReport.SalesReports.filter(x=>x.)

  // }



  addBankFile(event) {
    let file: File = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      let arrayBuffer: any = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join('');
      var workbook = XLSX.read(bstr, { type: 'binary' });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];

      this.bankReportJsonArray = XLSX.utils.sheet_to_json(worksheet, {
        raw: true,
        header: 1,
        dateNF: 'dd/mm/yyyy',
      });
    };
  }

  UploadBankReport() {

    let bankReports: BankReport[] = [];
    for (let i = 1; i < this.bankReportJsonArray.length; i++) {
      let row = this.bankReportJsonArray[i];
      let bankReport = new BankReport();
      bankReport.TransactionDate = this.datePipe.transform(StaticService.ExcelDate(row[0]), 'MM/dd/yyyy');
      bankReport.Description = row[1];
      bankReport.Withdrawal = !StaticService.isEmptyAndWhiteSpace(row[2]) ? parseFloat(row[2]) : 0;
      bankReport.Deposit = !StaticService.isEmptyAndWhiteSpace(row[3]) ? parseFloat(row[3]) : 0;
      bankReport.Balance = !StaticService.isEmptyAndWhiteSpace(row[4]) ? parseFloat(row[4]) : 0;

      bankReports.push(bankReport);
    }

    if (bankReports.length == 0) {
      this.errorMsg = 'No Data available for upload';
      this.successMsg = '';
      return;
    }

    this.spinnerService.show();
    this.bankRepSer.saveUploadReport(bankReports).subscribe(
      data => {
        console.log(data);
        this.successMsg = 'Data Uploaded';
        this.errorMsg = '';
        this.spinnerService.hide();
      },
      err => {
        console.log(err);
        this.successMsg = '';
        this.errorMsg = err.error.Message;
        this.spinnerService.hide();
      }
    )
  }




  createSearchForm() {
    let fromDate = new Date();
    fromDate.setDate(1);
    let toDate = new Date();

    this.searchForm = this.formBuilder.group({
      StartDate: [this.datePipe.transform(fromDate, 'y-MM-dd'), Validators.required],
      EndDate: [this.datePipe.transform(toDate, 'y-MM-dd'), Validators.required],
      IsPendingRecoChecked: [false],
      SearchType: ['']
    })
  }


}
