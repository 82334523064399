import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CashExpense, expenseSearch, ExpenseSummary } from 'src/app/models/cash-book/cash-expense';
import { ExpenseType } from 'src/app/models/cash-book/expense-type';
import { StaticService } from '../static.service';

@Injectable({
  providedIn: 'root'
})
export class CashExpenseService {


  constructor(private httpClient: HttpClient) {
  }

  ServiceUrl: string = StaticService.BaseServiceUrl + "CashExpense";


  gets(cashExpenseSearch?: expenseSearch): Observable<CashExpense[]> {
    let url = this.ServiceUrl;
    
    if (cashExpenseSearch) {
      url = this.ServiceUrl + '/Search/?' +
        'StartDate=' + cashExpenseSearch.StartDate
        + '&EndDate=' + cashExpenseSearch.EndDate
        + '&ExpenseType=' + cashExpenseSearch.ExpenseType;
    }

    return this.httpClient.get<CashExpense[]>(url);
  }

  GetExpenseTypes(): Observable<ExpenseType[]> {
    let url = this.ServiceUrl + "/ExpenseTypes";
    return this.httpClient.get<ExpenseType[]>(url);
  }


  save(cashExpense: CashExpense): Observable<string> {
    let url = this.ServiceUrl;
    return this.httpClient.post<string>(url, cashExpense);
  }

  delete(cashExpense: CashExpense): Observable<string> {
    let url = this.ServiceUrl + "/Delete";
    return this.httpClient.post<string>(url, cashExpense);
  }


  getExpenseSummary(cashExpenseSearch?: expenseSearch): Observable<ExpenseSummary[]> {
    let url = this.ServiceUrl;
    
    if (cashExpenseSearch) {
      url = this.ServiceUrl + '/ExpenseSummary/?' +
        'StartDate=' + cashExpenseSearch.StartDate
        + '&EndDate=' + cashExpenseSearch.EndDate
        + '&ExpenseType=' + cashExpenseSearch.ExpenseType;
    }

    return this.httpClient.get<ExpenseSummary[]>(url);
  }
}
