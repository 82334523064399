import { BankReport } from "./bank-report";

export class SalesReport {
    Id: number;
    InvoiceNo: string;
    GuestName: string;
    CheckInDate: string;
    CheckOutDate: string;
    RoomNo: string;
    TotalAmount: number;
    Cash: number;
    UPI: number;
    Online: number;
    TransDateDescription: string;
    ReconsilationAmount: number;
}
