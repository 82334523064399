import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HomePageData } from 'src/app/models/cash-book/home-page-data';
import { StaticService } from '../static.service';

@Injectable({
  providedIn: 'root'
})
export class HomePageDataService {

  
  constructor(private httpClient: HttpClient) {
  }

  ServiceUrl: string = StaticService.BaseServiceUrl + "HomePage";


  get(): Observable<HomePageData> {  
    return this.httpClient.get<HomePageData>(this.ServiceUrl);
  }
}
