import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BankReport } from 'src/app/models/SalesBankMapping/bank-report';
import { StaticService } from '../static.service';

@Injectable({
  providedIn: 'root'
})
export class BankReportService {


  constructor(private httpClient: HttpClient) {
  }

  ServiceUrl: string = StaticService.BaseServiceUrl + "BankReport";


  gets(): Observable<BankReport[]> {
    return this.httpClient.get<BankReport[]>(this.ServiceUrl);
  }


  saveUploadReport(bankReports: BankReport[]): Observable<string> {
    let url = this.ServiceUrl + '/SaveUploadReport';
    let request = JSON.stringify(bankReports);
    return this.httpClient.post<string>(url, bankReports);
  }
}
