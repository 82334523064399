import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { StaticService } from 'src/app/services/static.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private spinnerService: Ng4LoadingSpinnerService,
    private loginService: LoginService,
    private router: Router) { }

  logingForm: FormGroup;
  errorMsg: string = '';

  ngOnInit() {
    this.createLoginForm();
  }

  login() {

    if (this.logingForm.controls.UserName.invalid) {
      this.logingForm.controls.UserName.markAsTouched();
      console.log(this.logingForm.errors);
      return false;
    }

    if (this.logingForm.controls.Password.invalid) {
      this.logingForm.controls.Password.markAsTouched();
      console.log(this.logingForm.errors);
      return false;
    }

    let user = this.logingForm.value;
    this.spinnerService.show();
    this.loginService.getUserToken(user).subscribe(
      data => {
        if (data.includes('Error')) {
          this.errorMsg = data;
          this.spinnerService.hide();
          return;
        }

        this.loginService.setAdminTokenAtLocStorage(data);

        let navigateUrl = (StaticService.IsLaundryApp) ? '/stock' : '/sales-bank-map';
        this.router.navigateByUrl(navigateUrl);

      },
      err => {
        console.log(err);
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }


  createLoginForm() {
    this.logingForm = this.formBuilder.group({
      UserName: [null, Validators.required],
      Password: [null, Validators.required]
    })
  }
}
