import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as XLSX from 'xlsx';
import { SalesReport } from 'src/app/models/SalesBankMapping/sales-report';
import { SalesReportService } from 'src/app/services/SalesBankMapping/sales-report.service';
import { StaticService } from 'src/app/services/static.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.css']
})
export class SalesReportComponent implements OnInit {

  constructor(private spinnerService: Ng4LoadingSpinnerService,
    private salesRepSer: SalesReportService,
    private loginService: LoginService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder) { }

  salesReports: SalesReport[] = [];
  filteredSalesReports: SalesReport[] = [];
  totalUPIAmount: number;
  successMsg: string = "";
  errorMsg: string = "";
  salesReportId: any = {};
  searchForm: FormGroup;
  salesReportJsonArray: any[] = [];
  totalPendingRecoAmount: number = 0;
  bankReportData: any = {};

  ngOnInit() {
    this.createSearchForm();
    this.loadSalesReport();
  }

  getTableRowClass(salesReport: SalesReport) {
    if (salesReport.ReconsilationAmount === 0) {
      return '';
    }

    if (salesReport.UPI - salesReport.ReconsilationAmount
      <= StaticService.ReconsilationZeroLimit) {
      return 'table-success';
    }
    return 'table-warning';
  }

  getReconsilationStatus(salesReport: SalesReport): string {
    if (salesReport.ReconsilationAmount === 0) {
      return "No"
    }
    if (salesReport.UPI - salesReport.ReconsilationAmount
      <= StaticService.ReconsilationZeroLimit) {
      return "Yes";
    }
    return "Pending";
  }


  loadSalesReport() {

    let searchValue = this.searchForm.value;

    this.spinnerService.show();
    this.salesRepSer.getSalesReport(searchValue).subscribe(
      data => {
        this.filteredSalesReports = data;
        this.ShowPendingReconsilation();
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  ShowPendingReconsilation() {

    if (this.searchForm.value.IsPendingRecoChecked) {
      this.salesReports = this.filteredSalesReports.filter(x => x.UPI - x.ReconsilationAmount > StaticService.ReconsilationZeroLimit);

    } else {
      this.salesReports = this.filteredSalesReports;
    }

    this.totalPendingRecoAmount = this.filteredSalesReports.reduce(
      (sum, current) => sum + (current.UPI - current.ReconsilationAmount), 0);
  }

  loadBankReports(salesReportId: number) {

    if (this.bankReportData[salesReportId]) {
      this.bankReportData[salesReportId].showBankReport = !this.bankReportData[salesReportId].showBankReport;
      return;
    }

    this.spinnerService.show();
    this.salesRepSer.getBankReportBySalesId(salesReportId).subscribe(
      data => {
        this.bankReportData[salesReportId] = {
          BankReports: data,
          showBankReport: true
        }
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  addSalesFile(event) {
    let file: File = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      let arrayBuffer: any = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join('');
      var workbook = XLSX.read(bstr, { type: 'binary' });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];

      this.salesReportJsonArray = XLSX.utils.sheet_to_json(worksheet, {
        raw: true,
        header: 1,
        dateNF: 'dd/mm/yyyy',
      });
    };
  }

  UploadSalesReport() {

    let salesReports: SalesReport[] = [];
    for (let i = 1; i < this.salesReportJsonArray.length; i++) {
      let row = this.salesReportJsonArray[i];
      let salesReport = new SalesReport();
      salesReport.InvoiceNo = row[0];
      salesReport.GuestName = row[1];
      if (StaticService.isEmptyAndWhiteSpace(row[2])) {
        row[2] = row[3];
      }
      salesReport.CheckInDate = this.datePipe.transform(StaticService.ExcelDate(row[2]), 'MM/dd/yyyy');
      salesReport.CheckOutDate = this.datePipe.transform(StaticService.ExcelDate(row[3]), 'MM/dd/yyyy');
      salesReport.RoomNo = row[4];
      salesReport.TotalAmount = !StaticService.isEmptyAndWhiteSpace(row[5]) ? parseFloat(row[5]) : 0;
      salesReport.Cash = !StaticService.isEmptyAndWhiteSpace(row[6]) ? parseFloat(row[6]) : 0;
      salesReport.UPI = !StaticService.isEmptyAndWhiteSpace(row[7]) ? parseFloat(row[7]) : 0;
      salesReport.Online = !StaticService.isEmptyAndWhiteSpace(row[8]) ? parseFloat(row[8]) : 0;
      salesReport.TransDateDescription = row[9];

      let checkInDateValid = new Date(salesReport.CheckInDate).getFullYear();
      let checkOutDateValid = new Date(salesReport.CheckOutDate).getFullYear();
      if (checkInDateValid < 2022 || checkOutDateValid < 2022) {
        this.errorMsg = 'Excel Data Not correct';
        this.successMsg = '';
        return;
      }

      salesReports.push(salesReport);
    }

    if (salesReports.length == 0) {
      this.errorMsg = 'No Data available for upload';
      this.successMsg = '';
      return;
    }

    this.spinnerService.show();
    this.salesRepSer.saveUploadReport(salesReports).subscribe(
      data => {
        console.log(data);
        this.successMsg = 'Data Uploaded';
        this.errorMsg = '';
        this.spinnerService.hide();
      },
      err => {
        console.log(err);
        this.successMsg = '';
        this.errorMsg = err.error.Message;
        this.spinnerService.hide();
      }
    )
  }




  createSearchForm() {
    let fromDate = new Date();
    fromDate.setDate(1);
    let toDate = new Date();

    this.searchForm = this.formBuilder.group({
      StartDate: [this.datePipe.transform(fromDate, 'y-MM-dd'), Validators.required],
      EndDate: [this.datePipe.transform(toDate, 'y-MM-dd'), Validators.required],
      IsPendingRecoChecked: [false]
    })
  }

}
