import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InwardOutwardComponent } from './components/inward-outward/inward-outward.component';
import { InwardOutwardListComponent } from './components/inward-outward-list/inward-outward-list.component';
import { StockReportComponent } from './components/stock-report/stock-report.component';
import { BillListComponent } from './components/bill-list/bill-list.component';
import { LoginComponent } from './components/login/login.component';
import { ReadingComponent } from './components/reading/reading.component';
import { ReadingReportComponent } from './components/reading-report/reading-report.component';
import { SalesBankReportMapComponent } from './components/SalesBankMapping/sales-bank-report-map/sales-bank-report-map.component';
import { SalesReportComponent } from './components/SalesBankMapping/sales-report/sales-report.component';
import { CashDepositComponent } from './components/cashBook/cash-deposit/cash-deposit.component';
import { CashExpenseComponent } from './components/cashBook/cash-expense/cash-expense.component';
import { BankWithdrawalComponent } from './components/cashBook/bank-withdrawal/bank-withdrawal.component';
import { CashBookComponent } from './components/cashBook/cash-book/cash-book.component';
import { HomePageComponent } from './components/cashBook/home-page/home-page.component';
import { ExpenseSummaryComponent } from './components/cashBook/expense-summary/expense-summary.component';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'bills',
    component: BillListComponent
  },
  {
    path: 'inward-outward',
    component: InwardOutwardComponent
  },
  {
    path: 'inward-outward/edit/:id',
    component: InwardOutwardComponent
  },
  {
    path: 'inward-outward-list',
    component: InwardOutwardListComponent
  },
  {
    path: 'stock',
    component: StockReportComponent
  },
  {
    path: 'meter-reading',
    component: ReadingComponent
  },
  {
    path: 'reading-report',
    component: ReadingReportComponent
  },
  {
    path: 'sales-bank-map',
    component: SalesBankReportMapComponent
  },
  {
    path: 'sales-report',
    component: SalesReportComponent
  },
  {
    path: 'cash-deposit',
    component: CashDepositComponent
  },
  {
    path: 'cash-expense',
    component: CashExpenseComponent
  },
  {
    path: 'bank-withdrawal',
    component: BankWithdrawalComponent
  },
  {
    path: 'expense-summary',
    component: ExpenseSummaryComponent
  },
  {
    path: 'cash-book',
    component: CashBookComponent
  },
  {
    path: 'home',
    component: HomePageComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
