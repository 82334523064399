import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormArray } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Injectable({
  providedIn: 'root'
})
export class StaticService {

  constructor(private spinnerService: Ng4LoadingSpinnerService) { }

  public static get BaseServiceUrl(): string {
    // return "https://localhost:44353/api/";
     return "https://store.darshanhotel.in/vir_service/api/";
    // return "http://localhost:300/api/";
  }

  public static get IsLaundryApp(): boolean {
    return false;
  }

  public showSpinner() {
    this.spinnerService.show();
  }

  public hideSpinner() {
    this.spinnerService.hide();
  }

  public static get ReconsilationZeroLimit(): number {
    return 5;
  }

  static getUserNameAtLocStorage(): string {
    let adminToken = (JSON.parse(sessionStorage.getItem("UserToken")) as string);
    let adminTokenSplit = adminToken ? adminToken.split('$') : [];
    if (adminTokenSplit.length < 2) {
      return '';
    }

    return adminTokenSplit[1];
  }

  static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  static copyFormControl(control: AbstractControl) {
    if (control instanceof FormControl) {
      return new FormControl(control.value);
    } else if (control instanceof FormGroup) {
      const copy = new FormGroup({});
      Object.keys(control.getRawValue()).forEach(key => {
        copy.addControl(key, this.copyFormControl(control.controls[key]));
      });
      return copy;
    } else if (control instanceof FormArray) {
      const copy = new FormArray([]);
      control.controls.forEach(control => {
        copy.push(this.copyFormControl(control));
      })
      return copy;
    }
  }

  static isEmptyAndWhiteSpace(value) {
    if (value === null)
      return true;

    if (value === undefined)
      return true;

    if (typeof (value) === 'string' && value.trim() === '')
      return true;

    return false;
  }


  static ExcelDate(dateString: any) {
    return isNaN(dateString)
      ? new Date(dateString)
      : this.ExcelDateToJSDate(dateString);
  }

  private static ExcelDateToJSDate(serial) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(
      date_info.getFullYear(),
      date_info.getMonth(),
      date_info.getDate(),
      hours,
      minutes,
      seconds
    );
  }
}
