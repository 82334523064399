import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CashDeposit } from 'src/app/models/cash-book/cash-deposit';
import { StaticService } from '../static.service';

@Injectable({
  providedIn: 'root'
})
export class CashDepositService {

  
  constructor(private httpClient: HttpClient) {
  }

  ServiceUrl: string = StaticService.BaseServiceUrl + "CashDeposit";


  gets(): Observable<CashDeposit[]> {
    return this.httpClient.get<CashDeposit[]>(this.ServiceUrl);
  }


  save(cashDeposit: CashDeposit): Observable<string> {
    let url = this.ServiceUrl;
    return this.httpClient.post<string>(url, cashDeposit);
  }

  delete(cashDeposit: CashDeposit): Observable<string> {
    let url = this.ServiceUrl+"/Delete";
    return this.httpClient.post<string>(url, cashDeposit);
  }
}
