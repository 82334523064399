import { Injectable } from '@angular/core';
import { StaticService } from './static.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient) { }

  ServiceUrl: string = StaticService.BaseServiceUrl + "UserMaster";
  masterAdminUsers: string[] = ['SACHIN', 'SURAJ', 'MAYUR'];

  public getUserToken(user: User): Observable<string> {
    let url = this.ServiceUrl + "/GetUserToken";
    return this.httpClient.post<string>(url, user);
  }


  private _IsAdminLogin: boolean = false;
  public get IsAdminLogin(): boolean {
    return this._IsAdminLogin;
  }

  private _IsMasterAdminLogin: boolean = false;
  public get IsMasterAdminLogin(): boolean {
    return this._IsMasterAdminLogin
  }

  setMasterAdminFlag(tokenNo: string) {
    let adminTokenSplit = tokenNo ? tokenNo.split('$') : [];
    if (adminTokenSplit.length < 2) {
      return;
    }
    this._IsMasterAdminLogin = this.masterAdminUsers.includes(adminTokenSplit[1].toUpperCase());
  }

  setAdminTokenAtLocStorage(tokenNo: string): void {
    sessionStorage.setItem("UserToken", JSON.stringify(tokenNo));
    this._IsAdminLogin = true;
    this.setMasterAdminFlag(tokenNo);
  }

  getAdminTokenAtLocStorage(): string {
    let adminToken = (JSON.parse(sessionStorage.getItem("UserToken")) as string);
    this._IsAdminLogin = (adminToken != null);
    this.setMasterAdminFlag(adminToken);
    console.log(this.IsAdminLogin);
    return adminToken;
  }

  removeAdminTokenAtLocStorage(): void {
    sessionStorage.removeItem("UserToken");
    this._IsAdminLogin = false;
    this._IsMasterAdminLogin = false;
  }



}
