import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BankWithdrawal } from 'src/app/models/cash-book/bank-withdrawal';
import { ExpenseType } from 'src/app/models/cash-book/expense-type';
import { BankWithdrawalService } from 'src/app/services/cash-book/bank-withdrawal.service';
import { CashExpenseService } from 'src/app/services/cash-book/cash-expense.service';

@Component({
  selector: 'app-bank-withdrawal',
  templateUrl: './bank-withdrawal.component.html',
  styleUrls: ['./bank-withdrawal.component.css']
})
export class BankWithdrawalComponent implements OnInit {

  constructor(private spinnerService: Ng4LoadingSpinnerService,
    private bankWithdrawalService: BankWithdrawalService,
    private cashExpenseService: CashExpenseService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder) { }

  bankWithdrawals: BankWithdrawal[] = [];
  expenseTypes: ExpenseType[] = [];
  totalWithdrawalAmount: number;
  successMsg: string = "";
  errorMsg: string = "";
  searchForm: FormGroup;

  ngOnInit() {
    this.createSearchForm();
    this.loadExpenseType();
    this.loadBankWithdrawals();
  }

  saveBankWithdrawal(bankWithdrawal: BankWithdrawal) {

    bankWithdrawal.IsApproved = true;

    this.spinnerService.show();
    this.bankWithdrawalService.save(bankWithdrawal).subscribe(
      data => {

        this.spinnerService.hide();
      },
      err => {
        bankWithdrawal.IsApproved = false;
        console.log(err);
        this.spinnerService.hide();
      })
  }

  loadBankWithdrawals() {

    let searchValue = this.searchForm.value;

    this.spinnerService.show();
    this.bankWithdrawalService.gets(searchValue).subscribe(
      data => {
        this.bankWithdrawals = data;
        this.totalWithdrawalAmount = this.bankWithdrawals.reduce((sum, current) => sum + current.Amount, 0);
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  sortBankDiscription(longDescription: string) {
    return longDescription.replace("BY TRANSFER-UPI/CR/", "UPI-")
  }

  getExpenseType(id: number) {
    return this.expenseTypes.find(x => x.Id === id).Type;
  }

  loadExpenseType() {
    this.spinnerService.show();
    this.cashExpenseService.GetExpenseTypes().subscribe(
      data => {
        this.expenseTypes = data;
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  deleteBankWithdrawal(bankWithdrawal: BankWithdrawal) {
    this.spinnerService.show();
    this.bankWithdrawalService.delete(bankWithdrawal).subscribe(
      data => {        
        this.loadBankWithdrawals();
      },
      err => {
        this.spinnerService.hide();
      }
    );
  }

  createSearchForm() {
    let fromDate = new Date();
    fromDate.setDate(1);
    let toDate = new Date();

    this.searchForm = this.formBuilder.group({
      StartDate: [this.datePipe.transform(fromDate, 'y-MM-dd'), Validators.required],
      EndDate: [this.datePipe.transform(toDate, 'y-MM-dd'), Validators.required]
    })
  }

}
