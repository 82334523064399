import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { StaticService } from './services/static.service';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Laundry App';
  showMenu: boolean = true;
  mobileViewMaxSize = 768;
  isLaundryApp: boolean = StaticService.IsLaundryApp;

  constructor(private router: Router,
    private loginService: LoginService, private titleService: Title) {
    if (window.innerWidth < this.mobileViewMaxSize) {
      this.showMenu = false;
    }

    let pageTitle = this.isLaundryApp ? 'Laundry App' : 'Billing System';
    this.titleService.setTitle(pageTitle);

    if (!this.loginService.IsAdminLogin) {
      this.logout();
    }
  }

  changeRoute(routeValue) {
    if (window.innerWidth < this.mobileViewMaxSize) {
      this.showMenu = false;
    }

    //this.router.navigate([routeValue]);
  }

  logout() {
    this.loginService.removeAdminTokenAtLocStorage();
    this.router.navigate(["/login"]);
  }

}
